import React, { useState, useEffect } from 'react';
import { db, storage } from './../constants/firebase';
import resizeImage, { imageConfig } from './../constants/imageResizer';
import './categories.css';

const Categories = () => {
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [previewImage, setPreviewImage] = useState('');

    useEffect(() => {
        const categoriesRef = db.ref('categories');
        categoriesRef.on('value', (snapshot) => {
            const data = snapshot.val();
            const loadedCategories = [];
            for (let key in data) {
                loadedCategories.push({ ...data[key], id: key });
            }
            setCategories(loadedCategories);
        });

        return () => {
            categoriesRef.off();
        };
    }, []);

    const handleEdit = (category) => {
        setTitle(category.title);
        setEditingId(category.id);
        setPreviewImage(category.imageUrl);
    };

    const handleSave = async () => {
        let payload = { title: title.toUpperCase() };
        if (image !== null) {
            const fileURL = await saveImage();
            payload = { ...payload, imageUrl: fileURL };
        }
        const categoryRef = db.ref(`categories/${editingId}`);
        categoryRef.update(payload);
        setTitle('');
        setEditingId(null);
        setPreviewImage('');
        setImage(null);
    };

    const saveImage = async () => {
        const customConfig = { ...imageConfig, maxWidth: 920, maxHeight: 400 };
        const id = title.toLowerCase().replace(/ /g, '_').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const compressedImage = await resizeImage(image, customConfig);
        const storageRef = storage.ref();
        const fileRef = storageRef.child(id);
        await fileRef.put(compressedImage);
        const fileURL = await fileRef.getDownloadURL();
        return fileURL;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (editingId) {
            handleSave();
            return;
        }
        const formattedTitle = title.toUpperCase();
        const id = title.toLowerCase().replace(/ /g, '_').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const fileURL = await saveImage();
        db.ref('categories').push({
            title: formattedTitle,
            imageUrl: fileURL,
            id,
            active: true
        });
        setTitle('');
        setImage(null);
        setPreviewImage('');
    };

    const toggleCategory = async (categoryId, currentStatus) => {
        const categoryRef = db.ref(`categories/${categoryId}`);
        categoryRef.update({ active: !currentStatus });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
            setImage(file);
        }
    };

    return (
        <div className="categories-container">
            <div className="categories-list">
                <table>
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Título</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map(category => (
                            <tr key={category.id} style={{ opacity: category.active ? 1 : 0.5 }}>
                                <td><img src={category.imageUrl} alt={category.title} width="60" height="60" /></td>
                                <td>{category.title}</td>
                                <td>
                                    <button onClick={() => handleEdit(category)} style={{ marginRight: 8 }}>Editar</button>
                                    <button onClick={() => toggleCategory(category.id, category.active)}>
                                        {category.active ? 'Desactivar' : 'Activar'}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="categories-form">
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Título"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                    />
                    <label className="image-input-label">
                        {previewImage
                            ? <img src={previewImage} alt="Vista previa" className="preview-image" />
                            : 'Seleccionar imagen'}
                        <input
                            type="file"
                            onChange={handleImageChange}
                            accept="image/*"
                            style={{ display: "none" }}
                        />
                    </label>
                    <button type="submit">{editingId ? 'Guardar' : 'Agregar Categoría'}</button>
                </form>
            </div>
        </div>
    );
};

export default Categories;