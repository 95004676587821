import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const AdminDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="dashboard">
      <div className="button-container">
        <button className="admin-button" onClick={() => navigate("/categories")}>Administrar Categorías</button>
      </div>
      <div className="button-container">
        <button className="admin-button" onClick={() => navigate("/dishes")}>Administrar Platos</button>
      </div>
    </div>
  );


};

export default AdminDashboard;
