import React, { useState, useEffect } from 'react';
import { db, storage } from './../constants/firebase';
import resizeImage, { imageConfig } from './../constants/imageResizer';
import { foods } from './../constants/constants';
import './dishes.css';

const Platos = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState(null);
    const [isAvailable, setIsAvailable] = useState(false);
    const [isRecommended, setIsRecommended] = useState(false);
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [platos, setPlatos] = useState([]);
    const [editingPlato, setEditingPlato] = useState(null);
    const [nameFilter, setNameFilter] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');

    useEffect(() => {
        const unsubscribe = db.ref('categories').on('value', (snapshot) => {
            const data = snapshot?.val() ?? {};
            const loadedCategories = [];
            for (let id in data) {
                loadedCategories.push({ id, ...data[id] });
            }
            setCategories(loadedCategories);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = db.ref('platos').on('value', (snapshot) => {
            const data = snapshot?.val() ?? {};
            const loadedPlatos = [];
            for (let id in data) {
                loadedPlatos.push({ id, ...data[id] });
            }
            setPlatos(loadedPlatos);
        });
        return () => unsubscribe();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let fileURL;
        const key = name.toLowerCase().replace(/ /g, '_').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        if (image && image !== null) {
            const customConfig = { ...imageConfig, maxWidth: 920, maxHeight: 400 };
            const compressedImage = await resizeImage(image, customConfig);
            const storageRef = storage.ref();
            const fileRef = storageRef.child(key);
            await fileRef.put(compressedImage);
            fileURL = await fileRef.getDownloadURL();
        }
        const newPlato = {
            key, name, description, price: price.replace(/[^0-9]/g, ""), isAvailable, isRecommended, category
        };
        if (fileURL && fileURL !== null) {
            newPlato.imageUrl = fileURL;
        } else if (editingPlato?.imageUrl && editingPlato?.imageUrl !== null) {
            newPlato.imageUrl = editingPlato.imageUrl;
        }
        if (editingPlato) {
            db.ref(`platos/${editingPlato.id}`).update(newPlato);
            setEditingPlato(null);
        } else {
            db.ref('platos').push(newPlato);
        }
        resetForm();
    };

    const handleEdit = (plato) => {
        window.scrollTo(0, 0);
        setName(plato.name);
        setDescription(plato.description);
        setPrice(plato.price);
        setIsAvailable(plato.isAvailable);
        setIsRecommended(plato.isRecommended);
        setCategory(plato.category);
        setEditingPlato(plato);
    };

    const toggleAvailability = (platoId, currentAvailability) => {
        db.ref(`platos/${platoId}`).update({ isAvailable: !currentAvailability });
    };

    const resetForm = () => {
        setName('');
        setDescription('');
        setPrice('');
        setImage(null);
        setIsAvailable(false);
        setIsRecommended(false);
        setCategory('');
    };

    const formatPrice = (price) => {
        return parseInt(price).toLocaleString('es-CO', { minimumFractionDigits: 0 });
    }

    // const filteredPlatos = platos.filter(plato => {
    //     const nameMatch = plato.name.toLowerCase().includes(nameFilter.toLowerCase());
    //     const categoryMatch = categoryFilter ? plato.category === categoryFilter : true;
    //     return nameMatch && categoryMatch;
    // });

    const filteredPlatos = platos.filter((plato) => {
        const regex = new RegExp(nameFilter, 'i');
        return regex.test(plato.name) &&
            (!categoryFilter || plato.category === categoryFilter)
    });

    const resetFilters = () => {
        setNameFilter('');
        setCategoryFilter('');
    };

    const sync_foods = async () => {
        let _dishes = [];
        Object.keys(foods).forEach((key) => {
            _dishes = [..._dishes, ...foods[key].values.map(v => {
                return {
                    category: key,
                    description: v?.description ?? '',
                    isAvailable: true,
                    isRecommended: false,
                    key: v.name.toLowerCase().replace(/ /g, '_').normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                    name: v.name,
                    price: v.price.replace('.', '')
                }
            })];
        });
        await db.ref('platos').remove();
        _dishes.forEach((dish) => db.ref('platos').push(dish));
    }

    const deactivateAllDishes = async () => {
        const platosRef = db.ref('platos');
        platosRef.once('value', (snapshot) => {
            const updates = {};
            snapshot.forEach((childSnapshot) => {
                updates['/platos/' + childSnapshot.key + '/isAvailable'] = false;
            });
            db.ref().update(updates);
        });
    };

    return (
        <div className="platos">
            <div className="platos-list">
                <div className="filter-section">
                    <input
                        type="text"
                        value={nameFilter}
                        onChange={(e) => setNameFilter(e.target.value)}
                        placeholder="Filtrar por nombre"
                        style={{ padding: '8px', marginRight: '8px' }}
                    />
                    <select
                        value={categoryFilter}
                        onChange={(e) => setCategoryFilter(e.target.value)}
                        style={{ padding: '8px', marginRight: '8px' }}
                    >
                        <option value="">Todas las categorías</option>
                        {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.title}</option>)}
                    </select>
                    <button onClick={resetFilters} style={{ padding: '8px' }}>
                        Limpiar filtros
                    </button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Nombre</th>
                            <th>Categoría</th>
                            <th>Disponible</th>
                            <th>Precio</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    {filteredPlatos.length > 0 ? (
                        filteredPlatos.map(plato => (
                            <tr key={plato.id} style={{ opacity: plato.isAvailable ? 1 : 0.5 }}>
                                <td><img src={plato.imageUrl} alt={plato.name} width="60" height="60" /></td>
                                <td>{plato.name}</td>
                                <td>{categories.find(cat => cat.id.toLowerCase() === plato?.category?.toLowerCase())?.title || 'Sin categoría'}</td>
                                <td>{plato.isAvailable ? 'Sí' : 'No'}</td>
                                <td>{formatPrice(plato.price)}</td>
                                <td>
                                    <button onClick={() => handleEdit(plato)} style={{ marginRight: 8 }}>Editar</button>
                                    <button onClick={() => toggleAvailability(plato.id, plato.isAvailable)}>
                                        {plato.isAvailable ? 'Desactivar' : 'Activar'}
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">No hemos encontrado resultados por el filtro aplicado.</td>
                        </tr>
                    )}
                </table>
            </div>

            <div className="platos-form">
                <form onSubmit={handleSubmit}>
                    <input type="text" value={name} onChange={(e) => setName(e.target.value.toUpperCase())} placeholder="Nombre del plato" />
                    <input type="file" onChange={(e) => setImage(e.target.files[0])} />
                    <div className="preview-image">
                        {image && <img src={URL.createObjectURL(image)} alt="Vista previa" />}
                    </div>
                    <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Precio (ejemplo: $13.000)" />
                    <textarea value={description} onChange={(e) => setDescription(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))} placeholder="Descripción"></textarea>
                    <select value={category} onChange={(e) => setCategory(e.target.value)}>
                        <option value="" disabled>Seleccione una categoría</option>
                        {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.title}</option>)}
                    </select>
                    <label>
                        Disponible:
                        <input type="checkbox" checked={isAvailable} onChange={() => setIsAvailable(prev => !prev)} />
                    </label>
                    <label>
                        Recomendado:
                        <input type="checkbox" checked={isRecommended} onChange={() => setIsRecommended(prev => !prev)} />
                    </label>
                    <button type="submit">{editingPlato ? 'Editar Plato' : 'Agregar Plato'}</button>
                </form>
                <button style={{ marginTop: 24, width: '100%' }} onClick={deactivateAllDishes}>Desactivar todos los platos</button>
                {/* <button onClick={sync_foods}>Sincronizar Platos</button> */}
            </div>
        </div>
    );
};

export default Platos;
