import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './../constants/firebase';
import './index.css';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await auth.signInWithEmailAndPassword(email, password);
            navigate("/home");  // Redirige al dashboard.
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Administrador</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label htmlFor="email">Correo Electrónico</label>
                        <input 
                            type="email" 
                            id="email" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            required 
                        />
                    </div>

                    <div className="input-group">
                        <label htmlFor="password">Contraseña</label>
                        <input 
                            type="password" 
                            id="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            required 
                        />
                    </div>

                    <button type="submit">Iniciar Sesión</button>
                </form>
            </div>
        </div>
    );
};

export default AdminLogin;
