import { readAndCompressImage } from 'browser-image-resizer';

export const imageConfig = {
    quality: 0.7,
    maxWidth: 800,
    maxHeight: 800,
    autoRotate: true,
    debug: true,
};

const resizeImage = async (file, config) => {
    try {
        const compressedFile = await readAndCompressImage(file, config ?? imageConfig);
        return compressedFile;
    } catch (error) {
        console.error('Error while compressing the image', error);
        return file; // Return original file if compression fails
    }
};

export default resizeImage;
