import { useState, useEffect } from 'react';
import { auth } from './firebase';

function useAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        return auth.onAuthStateChanged((user) => {
            if (user) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
            setLoading(false);
        });
    }, []);

    return { isAuthenticated, loading };
}
export default useAuth;