import React, { useState } from 'react';
import './header.css';
import { Link } from 'react-router-dom';
import { food_list } from '../constants/constants';
import logo from '../Images/turin-logo.png';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="header-container">
          <div className="logo-container">
      <div className={`nav-toggle ${isNavOpen ? 'open' : ''}`} onClick={toggleNav}>
          <i className={`fas ${isNavOpen ? 'fa-times' : 'fa-bars'}`} />
        </div>
        <Link to={'/home'}>
            <img src={logo} alt="Logo del restaurante" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
